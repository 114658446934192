import { FunctionComponent } from 'react'
import orion from '../image001.png'

type OwnProps = {}

type Props = OwnProps

export const OrionPicture: FunctionComponent<Props> = () => {
  return <img src={orion} height={100} width={200} alt="logo" />
}

export default OrionPicture
