import {
  getFirestore,
  collection,
  addDoc,
  deleteDoc,
  getDoc,
  getDocs,
  setDoc,
  doc,
  CollectionReference,
  DocumentReference,
} from "firebase/firestore"

import initFb from "../../firebase"
import { WorkPlace } from "./types"

initFb()

const db = getFirestore()
const collectionName = "workplaces"

const workplaces = collection(
  db,
  collectionName
) as CollectionReference<WorkPlace>

const getReference = (id: string) =>
  doc(db, collectionName, id) as DocumentReference<WorkPlace>

export const createWorkPlace = async (workplace: WorkPlace) => {
  try {
    const docRef = await addDoc(workplaces, workplace)
    console.log("Document written with ID: ", docRef.id)
  } catch (e) {
    console.error("Error adding document: ", e)
  }
}

export const getWorkPlaces = async (workplaceId: string) => {
  try {
    const docs = await getDocs(workplaces)
    const workPlaces: WorkPlace[] = []
    docs.forEach((doc) => {
      workPlaces.push(doc.data())
    })
    return workPlaces
  } catch (e) {
    console.error("Error getting document: ", e)
  }
}

export const getWorkPlace = async (workplaceId: string) => {
  try {
    const ref = getReference(workplaceId)
    const workPlace = await getDoc(ref)
    return workPlace
  } catch (e) {
    console.error("Error getting document: ", e)
  }
}

export const deleteWorkPlace = async (workplaceId: string) => {
  try {
    const ref = getReference(workplaceId)
    await deleteDoc(ref)
    console.log("Document deleted with ID: ", workplaceId)
  } catch (e) {
    console.error("Error removing document: ", e)
  }
}
export const updateWorkPlace = async (
  workplaceId: string,
  updatedWorkPlace: WorkPlace
) => {
  try {
    const ref = getReference(workplaceId)
    console.log("Document updated with ID: ", workplaceId)
    return await setDoc(ref, updatedWorkPlace)
  } catch (e) {
    console.error("Error updating document: ", e)
  }
}
