import { Checkbox, Col, Radio, Row } from 'antd'
import { FunctionComponent } from 'react'
import theme from '../theme'
import ParagraphBold from './ParagraphBold'

type OwnProps = {
  checked: number
  labelText: string
}

type Props = OwnProps

export const Checklist: FunctionComponent<Props> = ({ checked, labelText }) => {
  let isChecked = false
  if (checked === 1) {
    isChecked = true
  }
  return (
    <Row>
      <Col span={18} style={{ textAlign: 'left' }}>
        <ParagraphBold labelText={labelText}></ParagraphBold>
      </Col>
      <Col span={6} style={{ textAlign: 'right' }}>
        <Radio.Group optionType="button">
          <Checkbox
            style={{ marginRight: theme.baseline * 2 }}
            checked={isChecked}
          ></Checkbox>
          <Checkbox
            style={{ marginRight: theme.baseline * -0 }}
            checked={!isChecked}
          ></Checkbox>
        </Radio.Group>
      </Col>
    </Row>
  )
}

export default Checklist
