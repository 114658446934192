import { FunctionComponent } from 'react'
import { Button } from 'antd'
import theme from '../theme'

type OwnProps = {
  buttonText: string
}

type Props = OwnProps

export const FormSubmitButton: FunctionComponent<Props> = ({ buttonText }) => {
  return (
    <Button
      type="primary"
      htmlType="submit"
      style={{
        backgroundColor: theme.colors.button,
        width: '100%',
        height: 40,
        fontWeight: 600,
        borderRadius: theme.borderRadius,
      }}
    >
      {buttonText}
    </Button>
  )
}

export default FormSubmitButton
