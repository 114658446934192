import React, { useEffect, useState } from 'react'
import './style.css'
import theme from '../../theme'
import SmallLogoPicture from '../../components/SmallLogoPicture'
import Search from 'antd/lib/input/Search'
import { useHistory } from 'react-router'
import { Layout, PageHeader } from 'antd'
import { Content, Header } from 'antd/lib/layout/layout'
import { collection, getDocs, getFirestore } from 'firebase/firestore'
import ViewFormButton from '../../components/ViewFormButton'
import { WorkPlace } from '../../modules/workplaces/types'
import { getAuth } from 'firebase/auth'
import moment from 'moment'
import Paragraph from 'antd/lib/typography/Paragraph'
import UpdateWorkplace from '../../components/UpdateWorkplace'

type OwnProps = {}

type Props = OwnProps

export const ViewWorkplacesUser: React.FC<Props> = () => {
  const history = useHistory()
  const [workplaceList, setWorkplaceList] = useState<any[]>([])
  const [originalList, setOriginalList] = useState<any[]>([])
  const [shouldShow, setShouldShow] = useState(false)
  const auth = getAuth()
  const [selectedWorkplace, setSelectedWorkplace] = useState<WorkPlace>()
  const db = getFirestore()

  const goBack = () => {
    history.push('/home')
  }
  if (!auth.currentUser) {
    history.push('/')
  }

  const goBackFromView = () => {
    setShouldShow(false)
  }

  useEffect(() => {
    console.log('test')
    setWorkplaces()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const setWorkplaces = async () => {
    const workplacesCollection = collection(db, 'workplaces')
    const workplacesDocs = await getDocs(workplacesCollection)
    const workplacesList: WorkPlace[] = workplacesDocs.docs.map(
      (doc) => ({ ...doc.data(), id: doc.id } as WorkPlace),
    )
    const workplacesUserList = workplacesList.filter(
      (workplace) => workplace.worker === auth.currentUser?.displayName,
    )
    if (!workplacesUserList) {
      return
    }
    const list = workplacesUserList.reduce((acc, curr) => {
      const workplace = curr
      const month = moment(curr.date * 1000).format('MMMM')

      if (!acc[month]) {
        acc[month] = [workplace]
      } else {
        acc[month] = [...acc[month], workplace]
      }
      return acc
    }, {} as any)
    setOriginalList(list)
    setWorkplaceList(list)
  }

  const renderForm = (workplace: WorkPlace) => {
    setShouldShow(!shouldShow)
    setSelectedWorkplace(workplace)
  }

  const onSearch = (input: string) => {
    if (!input) {
      setWorkplaces()
    }
    const workplacesList = Object.values(originalList).flat()
    const workplacesForUserList = workplacesList.filter(
      (workplace) => workplace.worker === auth.currentUser?.displayName,
    )
    const filteredWorkplaces = workplacesForUserList.filter((workplace) =>
      workplace.location.toLowerCase().includes(input.toLowerCase()),
    )
    if (!workplacesForUserList) {
      return
    }
    const list = filteredWorkplaces.reduce((acc, curr) => {
      const workplace = curr
      const month = moment(curr.date * 1000).format('MMMM')

      if (!acc[month]) {
        acc[month] = [workplace]
      } else {
        acc[month] = [...acc[month], workplace]
      }
      return acc
    }, {} as any)

    setWorkplaceList(list)
  }

  return (
    <>
      <Layout>
        <Header>
          <div
            style={{
              display: 'flex',
              flex: 1,
              height: '100%',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {!shouldShow ? (
              <SmallLogoPicture goBack={goBack}></SmallLogoPicture>
            ) : (
              <SmallLogoPicture goBack={goBackFromView}></SmallLogoPicture>
            )}
          </div>
        </Header>
        <Content>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignContent: 'center',
              justifyContent: 'center',
              margin: 'auto',
              maxWidth: 500,
              minHeight: '50vh',
            }}
          >
            {!shouldShow && (
              <>
                <PageHeader
                  style={{
                    color: theme.colors.button,
                    fontSize: 25,
                    fontWeight: 600,
                    textAlign: 'center',
                  }}
                >
                  Registrerade arbetsplatser
                </PageHeader>
                <Search
                  placeholder="Arbetsplats"
                  onChange={(e) => onSearch(e.target.value)}
                  className="searchInput"
                  style={{
                    width: '100%',
                    backgroundColor: theme.colors.primary,
                    marginBottom: theme.baseline * 4,
                  }}
                />
              </>
            )}
            {!shouldShow &&
              workplaceList &&
              Object.entries(workplaceList).map(([month, workplaces]) => {
                return (
                  <div>
                    <Paragraph
                      style={{
                        fontSize: 20,
                        fontWeight: 600,
                        color: theme.colors.button,
                      }}
                    >
                      {month}
                    </Paragraph>
                    {workplaces.length &&
                      workplaces.map((workplace: WorkPlace) => {
                        return (
                          <ViewFormButton
                            buttonText={workplace.worker || ' '}
                            onPress={() => renderForm(workplace)}
                            date={workplace.date}
                          />
                        )
                      })}
                  </div>
                )
              })}
            {shouldShow && selectedWorkplace && (
              <UpdateWorkplace selectedWorkplace={selectedWorkplace} />
            )}
          </div>
        </Content>
      </Layout>
    </>
  )
}

export default ViewWorkplacesUser
