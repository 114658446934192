import React, { FC } from 'react'
import './style.css'
import { Form, Input, Layout, PageHeader } from 'antd'
import FormSubmitButton from '../../components/FormSubmitButton'
import FormInput from '../../components/FormInput'
import SmallLogoPicture from '../../components/SmallLogoPicture'
import theme from '../../theme'
import {
  getAuth,
  createUserWithEmailAndPassword,
  updateProfile,
} from 'firebase/auth'
import { useHistory } from 'react-router'
import { Content, Header } from 'antd/lib/layout/layout'

type OwnProps = {}

type Props = OwnProps

export const Login: FC<Props> = () => {
  const history = useHistory()
  const auth = getAuth()

  if (!auth.currentUser) {
    history.push('/')
  }

  const onFinish = (values: any) => {
    createUserWithEmailAndPassword(auth, values.email, values.password)
      .then((userCredential) => {
        updateProfile(userCredential.user, {
          displayName: values.name,
        })
        window.alert('Användare skapad!')
        console.log('The user', userCredential.user)
      })
      .catch((error) => {
        window.alert('Något gick fel, var vänlig prova igen!')
      })
  }

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo)
  }

  const goBack = () => {
    history.push('/home')
  }

  return (
    <>
      <Layout>
        <Header>
          <div
            style={{
              display: 'flex',
              flex: 1,
              height: '100%',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <SmallLogoPicture goBack={goBack}></SmallLogoPicture>
          </div>
        </Header>
        <Content>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignContent: 'center',
              justifyContent: 'center',
              margin: 'auto',
              maxWidth: 500,
              minHeight: '50vh',
            }}
          >
            <PageHeader
              style={{
                color: '#304659',
                fontSize: 25,
                fontWeight: 600,
                textAlign: 'center',
              }}
            >
              Skapa användare:
            </PageHeader>
            <Form
              name="basic"
              labelCol={{ span: 4 }}
              wrapperCol={{ span: 16 }}
              initialValues={{ remember: true }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <FormInput
                labelText={'Email'}
                name={'email'}
                required={true}
                message="Du måste ange en email"
                InputComponent={
                  <Input
                    style={{ backgroundColor: theme.colors.primary }}
                    placeholder="anton@pigmenta.se"
                  />
                }
              ></FormInput>
              <FormInput
                labelText={'Namn'}
                name={'name'}
                required={true}
                message="Du måste ange ett namn"
                InputComponent={
                  <Input
                    style={{ backgroundColor: theme.colors.primary }}
                    placeholder="Namn Efternamn"
                  />
                }
              ></FormInput>

              <Form.Item
                style={{ fontWeight: 500 }}
                label="Lösenord"
                name="password"
                rules={[
                  { required: true, message: 'Du måste ange ett lösenord!' },
                ]}
              >
                <Input.Password
                  style={{ backgroundColor: theme.colors.primary }}
                  className="passwordInput"
                />
              </Form.Item>
              <Form.Item style={{ justifyContent: 'center' }}>
                <FormSubmitButton buttonText="Skapa" />
              </Form.Item>
            </Form>
          </div>
        </Content>
      </Layout>
    </>
  )
}

export default Login
