const theme = {
  baseline: 8,
  borderRadius: 4,
  colors: {
    primary: '#E1E9F0',
    button: '#304659',
  },
}

export default theme
