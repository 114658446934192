import React from 'react'
import './App.css'
import initFb from './firebase'
import Login from './pages/Login/Login'
import Home from './pages/Home/Home'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import RegisterWorkplace from './pages/RegisterWorkplace/RegisterWorkplace'
import ViewWorkPlaces from './pages/ViewWorkplaces/ViewWorkplaces'
import ViewWorkplacesUser from './pages/ViewWorkplacesUser/ViewWorkplacesUser'
import CreateUser from './pages/CreateUser/CreateUser'
import { getAuth } from 'firebase/auth'

initFb()
const auth = getAuth()

auth.onAuthStateChanged((user) => console.log(user))

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/home">
          <Home />
        </Route>
        <Route path="/registerWorkplace">
          <RegisterWorkplace />
        </Route>
        <Route path="/createUser">
          <CreateUser />
        </Route>
        <Route path="/viewWorkplaces">
          <ViewWorkPlaces />
        </Route>
        <Route path="/viewWorkplacesUser">
          <ViewWorkplacesUser />
        </Route>
        <Route path="/">
          <Login />
        </Route>
      </Switch>
    </Router>
  )
}

export default App
