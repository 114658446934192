import { Form } from 'antd'
import { Rule } from 'rc-field-form/lib/interface'
import React, { FunctionComponent } from 'react'

type OwnProps = {
  labelText: string
  name: string
  required: boolean
  message?: string
  rules?: Rule[]
  InputComponent?: React.ReactElement
}

type Props = OwnProps

export const FormInput: FunctionComponent<Props> = ({
  labelText,
  name,
  required,
  message,
  InputComponent,
  rules,
}) => {
  return (
    <Form.Item
      style={{ fontWeight: 500 }}
      label={labelText}
      name={name}
      rules={[{ required, message, ...rules }]}
    >
      {InputComponent}
    </Form.Item>
  )
}

export default FormInput
