import React, { useState } from 'react'
import './style.css'
import { Alert, Button, Form, Input, Layout, PageHeader } from 'antd'
import FormSubmitButton from '../../components/FormSubmitButton'
import FormInput from '../../components/FormInput'
import BigLogoPicture from '../../components/BigLogoPicture'
import theme from '../../theme'
import {
  getAuth,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
} from 'firebase/auth'
import { Content, Header } from 'antd/lib/layout/layout'
import { useHistory } from 'react-router'

type OwnProps = {}

type Props = OwnProps

export const Login: React.FC<Props> = () => {
  const [failedLogin] = useState(false)
  const [forgotPassword, setForgotPassword] = useState(false)
  const auth = getAuth()
  const history = useHistory()

  auth.onAuthStateChanged((user) => {
    if (user) {
      history.push('/home')
    }
  })

  const onFinish = (values: any) => {
    //Redirect to home page, this should probably be done in a diffrent way with react router. And only if firebase login succeeds
    const emailWithoutWhitespace = values?.email?.replace(/\s/g, '')
    signInWithEmailAndPassword(auth, emailWithoutWhitespace, values.password)
      .then((userCredential) => {
        // Signed in
        history.push('/home')
        // ...
      })
      .catch((error) => {
        if (forgotPassword) {
          sendPasswordResetEmail(auth, values.email)
          setForgotPassword(false)
          window.alert(
            'Vi har skickat ett mail till dig för att återställa ditt lösenord.',
          )
        } else {
          window.alert(
            'Felaktigt inlogg, var snäll och kontrollera uppgifterna.',
          )
        }
      })
  }

  const onFinishFailed = (errorInfo: any) => {
    if (forgotPassword) {
      sendPasswordResetEmail(auth, errorInfo.values.email)
        .then(() => {
          window.alert(
            'Vi har skickat ett mail till dig för att återställa ditt lösenord.',
          )
          setForgotPassword(false)
        })
        .catch((error) => {
          window.alert(
            'Vi hittade inget konto med den mailadressen, kontrollera angiven mailadress.',
          )
        })
    }
  }

  return (
    <>
      <Layout>
        <Header>
          <div
            style={{
              display: 'flex',
              flex: 1,
              height: '100%',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <BigLogoPicture />
          </div>
        </Header>
        <Content>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignContent: 'center',
              justifyContent: 'center',
              margin: 'auto',
              maxWidth: 500,
              minHeight: '50vh',
            }}
          >
            <PageHeader
              style={{
                color: '#304659',
                fontSize: 25,
                fontWeight: 600,
                textAlign: 'center',
              }}
            >
              Användaruppgifter:
            </PageHeader>
            <Form
              name="basic"
              labelCol={{ span: 4 }}
              wrapperCol={{ span: 16 }}
              initialValues={{ remember: true }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <FormInput
                labelText={'Email'}
                name={'email'}
                required={true}
                message="Du måste ange en email!"
                InputComponent={
                  <Input
                    style={{ backgroundColor: theme.colors.primary }}
                    placeholder="anton@pigmenta.se"
                  />
                }
              />
              <FormInput
                labelText={'Lösenord'}
                name={'password'}
                required={true}
                message="Du måste ange ett lösenord!"
                rules={[
                  {
                    required: true,
                    message: 'Du måste ange ett lösenord!',
                  },
                ]}
                InputComponent={
                  <Input.Password
                    style={{ backgroundColor: theme.colors.primary }}
                    className="passwordInput"
                    placeholder="******"
                  />
                }
              />

              {failedLogin && (
                <Alert
                  message="Email / Lösenord är inte korrekt"
                  type="error"
                />
              )}

              <Form.Item style={{ justifyContent: 'center' }}>
                <FormSubmitButton buttonText="Logga in" />
              </Form.Item>
              <Form.Item style={{ justifyContent: 'center' }}>
                <Button
                  htmlType="submit"
                  onClick={() => setForgotPassword(true)}
                  style={{
                    border: '2px solid #304659',
                    width: '100%',
                    height: 40,
                    fontWeight: 600,
                    borderRadius: theme.borderRadius,
                  }}
                >
                  Glömt lösenord
                </Button>
              </Form.Item>
            </Form>
          </div>
        </Content>
      </Layout>
    </>
  )
}

export default Login
