import { FunctionComponent } from 'react'
import { Button } from 'antd'
import theme from '../theme'

type OwnProps = {
  buttonText: string
  handleClick: () => void
}

type Props = OwnProps

export const MainButton: FunctionComponent<Props> = ({
  buttonText,
  handleClick,
}) => {
  return (
    <Button
      type="primary"
      style={{
        backgroundColor: theme.colors.button,
        width: '100%',
        height: 40,
        marginBottom: theme.baseline * 2,
        fontWeight: 600,
        borderRadius: theme.borderRadius,
      }}
      onClick={handleClick}
    >
      {buttonText}
    </Button>
  )
}

export default MainButton
