import { initializeApp } from 'firebase/app'

const getFirebaseConfig = () => {
  const firebaseConfig = {
    apiKey: 'AIzaSyAr6q2BsyY15Q01hIVRQHoqV_Le3X9DEiA',
    authDomain: 'pigmenta-629f8.firebaseapp.com',
    projectId: 'pigmenta-629f8',
    storageBucket: 'pigmenta-629f8.appspot.com',
    messagingSenderId: '722265622518',
    appId: '1:722265622518:web:af39a41ed412bdece7e9d7',
    measurementId: 'G-WTEZ96N6Z4',
  }

  // Initialize Firebase
  const app = initializeApp(firebaseConfig)
  return app
}
export default getFirebaseConfig
