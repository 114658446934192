import { FunctionComponent } from 'react'

import handledning from '../IMG_4954.png'

type OwnProps = {}

type Props = OwnProps

export const HandledningPicture: FunctionComponent<Props> = () => {
  return <img src={handledning} height={50} width={250} alt="logo" />
}

export default HandledningPicture
