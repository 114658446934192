import { FunctionComponent } from 'react'
import logo from '../pigmentaLogo.svg'

type OwnProps = {}

type Props = OwnProps

export const BigLogoPicture: FunctionComponent<Props> = () => {
  return (
    <img
      src={logo}
      alt="logo"
      style={{
        height: '100%',
      }}
    />
  )
}

export default BigLogoPicture
