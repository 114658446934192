import { Checkbox, Col, Radio, Row } from 'antd'
import { FunctionComponent, useState } from 'react'
import theme from '../theme'
import ParagraphBold from './ParagraphBold'

type OwnProps = {
  labelText: string
  setCb: (cb: number) => void
  startValue?: boolean
}

type Props = OwnProps

export const Checklist: FunctionComponent<Props> = ({
  labelText,
  setCb,
  startValue,
}) => {
  const [isChecked, setIsChecked] = useState(startValue || false)
  const onClickOk = () => {
    setIsChecked(!isChecked)
    setCb(1)
  }
  const onClickAn = () => {
    setIsChecked(!isChecked)
    setCb(0)
  }
  return (
    <Row>
      <Col span={18} style={{ textAlign: 'left' }}>
        <ParagraphBold labelText={labelText}></ParagraphBold>
      </Col>
      <Col span={6} style={{ textAlign: 'right' }}>
        <Radio.Group optionType="button">
          <Checkbox
            style={{ marginRight: theme.baseline * 2 }}
            value={1}
            checked={isChecked}
            onClick={onClickOk}
          ></Checkbox>
          <Checkbox
            style={{ marginRight: theme.baseline * -0 }}
            value={0}
            checked={!isChecked}
            onClick={onClickAn}
          ></Checkbox>
        </Radio.Group>
      </Col>
    </Row>
  )
}

export default Checklist
