import { Col, Form, Layout, Row, Typography, Image, Input, Button } from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import { Content } from 'antd/lib/layout/layout'
import Title from 'antd/lib/typography/Title'
import { FC } from 'react'
import theme from '../theme'
import ParagraphBold from './ParagraphBold'
import RenderChecklist from './RenderChecklist'

type OwnProps = {
  workplace: any
}

type Props = OwnProps

export const Login: FC<Props> = (workplace) => {
  const multiPlyDate = workplace.workplace.date * 1000
  var formatedDate = new Date(multiPlyDate).toLocaleDateString('en-US')
  const checkboxNames = [
    'Allmän ordning',
    'Bockar / Stegar',
    'Ställningar',
    'Trappor / Höjder',
    'Personal utrymmen / Wc',
    'Maskinell utrustning',
    'Första hjälpen',
    'Buller',
    'Kemiska ämnen',
  ]
  const updateWorkplace = () => {
    //replace the old workplace with the new one in firebase
  }
  return (
    <>
      <Layout>
        <Content style={{ margin: -theme.baseline }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignContent: 'center',
              justifyContent: 'center',
              margin: 'auto',
              maxWidth: 500,
              minHeight: '50vh',
              width: '100%',
            }}
          >
            <Form
              name="basic"
              labelCol={{ span: 4 }}
              wrapperCol={{ span: 16 }}
              initialValues={{ remember: true }}
              autoComplete="off"
            >
              <ParagraphBold labelText="Inlämnad av:"></ParagraphBold>
              <Input
                style={{ marginBottom: theme.baseline }}
                value={workplace.workplace.worker}
                contentEditable={false}
              ></Input>
              <ParagraphBold labelText="Arbetsplats:"></ParagraphBold>
              <Input
                style={{ marginBottom: theme.baseline }}
                defaultValue={workplace.workplace.location}
              ></Input>
              <ParagraphBold labelText="Risk:"></ParagraphBold>
              <TextArea defaultValue={workplace.workplace.risks}></TextArea>
              <Row style={{ marginTop: theme.baseline }}>
                <Col span={18} style={{ textAlign: 'left' }}>
                  <ParagraphBold labelText="Checklista:"></ParagraphBold>
                </Col>
                <Col span={3} style={{ textAlign: 'right' }}>
                  <ParagraphBold labelText="Ok"></ParagraphBold>
                </Col>
                <Col span={3} style={{ textAlign: 'right' }}>
                  <ParagraphBold labelText="An"></ParagraphBold>
                </Col>
              </Row>
              {workplace.workplace.checklist.map(
                (checklist: any, index: number) => (
                  <RenderChecklist
                    labelText={checkboxNames[index]}
                    checked={workplace.workplace.checklist[index].status}
                  />
                ),
              )}
              <Typography
                style={{ textAlign: 'left', paddingTop: theme.baseline * 4 }}
              >
                <Title
                  style={{
                    fontSize: 20,
                    color: theme.colors.button,
                    textAlign: 'center',
                  }}
                >
                  Om olyckan är framme
                </Title>
                <ParagraphBold labelText="Ring 112 om skadan kräver."></ParagraphBold>
                <ParagraphBold labelText="Se till att den skadade är omhändertagen."></ParagraphBold>
                <ParagraphBold labelText="Ring 0735004372 Lars Glassel, Alt 0737003884 Benita Glassel"></ParagraphBold>
                <ParagraphBold labelText="Försök skaffa info om olyckan."></ParagraphBold>
                <ParagraphBold labelText="Kontakta anhörig"></ParagraphBold>
                <ParagraphBold labelText="Försök vara lugn."></ParagraphBold>
              </Typography>
              <Title
                style={{
                  fontSize: 20,
                  color: theme.colors.button,
                  textAlign: 'center',
                }}
              >
                Info om arbetsplats
              </Title>
              <Input
                style={{ marginBottom: theme.baseline }}
                defaultValue={workplace.workplace.color}
              ></Input>
              <Form.Item
                name="date"
                style={{
                  marginTop: theme.baseline * 4,
                  justifyContent: 'center',
                }}
              >
                <ParagraphBold labelText="Datum:"></ParagraphBold>
                <input
                  style={{
                    width: '100%',
                    backgroundColor: theme.colors.primary,
                  }}
                  value={formatedDate}
                ></input>
              </Form.Item>
              <Form.Item style={{ justifyContent: 'center' }}>
                <ParagraphBold labelText="Signatur:"></ParagraphBold>
                <Image src={workplace.workplace.signature}></Image>
              </Form.Item>
            </Form>
            <Button onClick={updateWorkplace}>Uppdatera arbetsplats</Button>
          </div>
        </Content>
      </Layout>
    </>
  )
}

export default Login
