import { Header } from 'antd/lib/layout/layout'
import React from 'react'
import BigLogoPicture from './BigLogoPicture'
import backArrow from '../backArrow.svg'

const HeaderComponent: React.FC<{
  backButton?: boolean
  backButtonPress?: () => void
}> = ({ backButton, backButtonPress }) => {
  return (
    <Header>
      <div
        style={{
          display: 'flex',
          flex: 1,
          height: '100%',
          width: '100%',
          justifyContent: backButton ? 'space-between' : 'center',
          alignItems: 'center',
        }}
      >
        {backButton && (
          <img
            src={backArrow}
            alt="backArrow"
            style={{
              height: '50%',
            }}
            onClick={backButtonPress}
          />
        )}
        <BigLogoPicture />
      </div>
    </Header>
  )
}

export default HeaderComponent
