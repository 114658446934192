import { Form } from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import { FunctionComponent } from 'react'
import theme from '../theme'

type OwnProps = {
  labelText: string
  name: string
  required: boolean
  message?: string
  initialValue?: string
}

type Props = OwnProps

export const FormTextArea: FunctionComponent<Props> = ({
  labelText,
  name,
  required,
  message,
  initialValue,
}) => {
  return (
    <Form.Item
      style={{ fontWeight: 700, color: theme.colors.button }}
      label={labelText}
      name={name}
      initialValue={initialValue}
      rules={[{ required: required, message: message }]}
    >
      <TextArea style={{ backgroundColor: theme.colors.primary }} />
    </Form.Item>
  )
}

export default FormTextArea
