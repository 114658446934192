import { Form } from 'antd'
import React, { FunctionComponent, RefObject } from 'react'
import ParagraphBold from './ParagraphBold'
import SignatureCanvas from 'react-signature-canvas'
import theme from '../theme'

type OwnProps = {
  labelText: string
  signature: RefObject<SignatureCanvas>
}

type Props = OwnProps

export const SignatureComponent: FunctionComponent<Props> = ({
  labelText,
  signature,
}) => {
  return (
    <Form.Item style={{ justifyContent: 'center' }}>
      <ParagraphBold labelText={labelText}></ParagraphBold>
      <span className="signature-canvas-container">
        <SignatureCanvas
          ref={signature}
          backgroundColor={theme.colors.primary}
        ></SignatureCanvas>
      </span>
    </Form.Item>
  )
}

export default SignatureComponent
