import { FC, useRef, useState } from 'react'
import {
  Button,
  Col,
  DatePicker,
  Form,
  Layout,
  PageHeader,
  Row,
  Typography,
} from 'antd'
import { Content } from 'antd/lib/layout/layout'
import { getAuth } from 'firebase/auth'
import moment from 'moment'
import { useHistory } from 'react-router-dom'
import { CheckListItem, WorkPlace } from '../modules/workplaces/types'
import theme from '../theme'
import Checklist from './Checklist'
import FormSubmitButton from './FormSubmitButton'
import FormTextArea from './FormTextArea'
import ParagraphBold from './ParagraphBold'
import SignatureComponent from './SignatureComponent'
import Title from 'antd/lib/typography/Title'
import SignatureCanvas from 'react-signature-canvas'
import { updateWorkPlace } from '../modules/workplaces/workplaces.service'

type OwnProps = {
  selectedWorkplace: WorkPlace
}

type Props = OwnProps

export const UpdateWorkplace: FC<Props> = (selectedWorkplace) => {
  const history = useHistory()
  const [cb1, setCb1] = useState(0)
  const [cb2, setCb2] = useState(0)
  const [cb3, setCb3] = useState(0)
  const [cb4, setCb4] = useState(0)
  const [cb5, setCb5] = useState(0)
  const [cb6, setCb6] = useState(0)
  const [cb7, setCb7] = useState(0)
  const [cb8, setCb8] = useState(0)
  const [cb9, setCb9] = useState(0)
  const auth = getAuth()
  const user = auth.currentUser
  const [date, setDate] = useState()
  if (!auth.currentUser) {
    history.push('/')
  }

  const onFinish = async (values: any) => {
    const checkList: CheckListItem[] = [
      {
        label: 'Allmän ordning',
        status: cb1,
      },
      {
        label: 'Bockar / Stegar',
        status: cb2,
      },
      {
        label: 'Ställningar',
        status: cb3,
      },
      {
        label: 'Trappor / Höjder',
        status: cb4,
      },
      {
        label: 'Personal utrymmen / Wc',
        status: cb5,
      },
      {
        label: 'Maskinell utrustning',
        status: cb6,
      },
      {
        label: 'Första hjälpen',
        status: cb7,
      },
      {
        label: 'Buller',
        status: cb8,
      },
      {
        label: 'Kemiska ämnen',
        status: cb9,
      },
    ]
    values.date = moment(date).unix()
    const workPlace: WorkPlace = {
      worker: user?.displayName || ' ',
      location: values.workplace,
      risks: values.risk || 'Inga övriga risker',
      checklist: checkList,
      date: selectedWorkplace.selectedWorkplace.date,
      color: values.color || ' ',
      signature: signature.current && signature.current.toDataURL(),
    }
    if (selectedWorkplace.selectedWorkplace.id) {
      await updateWorkPlace(selectedWorkplace.selectedWorkplace.id, workPlace)
      window.alert('Dokument inskickat, tack!')
      history.push('/home')
    }
  }

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo)
    //Display message for failed login
  }

  const signature = useRef<SignatureCanvas>(null)
  const clearSignature = () => {
    signature.current?.clear()
  }
  function onChange(value: any) {
    setDate(value)
  }

  return (
    <>
      <Layout>
        <Content>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignContent: 'center',
              justifyContent: 'center',
              margin: 'auto',
              maxWidth: 500,
              minHeight: '50vh',
            }}
          >
            <PageHeader
              style={{
                color: theme.colors.button,
                fontSize: 25,
                fontWeight: 600,
                textAlign: 'center',
              }}
            >
              Registrera arbetsplats
            </PageHeader>
            <Form
              name="basic"
              labelCol={{ span: 4 }}
              wrapperCol={{ span: 16 }}
              initialValues={{ remember: true }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <FormTextArea
                labelText={'Arbetsplats'}
                name={'workplace'}
                initialValue={
                  selectedWorkplace.selectedWorkplace.location || ' '
                }
                required={true}
                message="Du måste ange en arbetsplats"
              ></FormTextArea>
              <FormTextArea
                labelText={'Övriga risker'}
                initialValue={selectedWorkplace.selectedWorkplace.risks || ' '}
                name={'risk'}
                required={false}
              ></FormTextArea>
              <Row>
                <Col span={18} style={{ textAlign: 'left' }}>
                  <ParagraphBold labelText="Checklista:"></ParagraphBold>
                </Col>
                <Col span={3} style={{ textAlign: 'right' }}>
                  <ParagraphBold labelText="Ok"></ParagraphBold>
                </Col>
                <Col span={3} style={{ textAlign: 'right' }}>
                  <ParagraphBold labelText="An"></ParagraphBold>
                </Col>
              </Row>
              <Checklist
                labelText="Allmän ordning"
                setCb={setCb1}
                startValue={
                  selectedWorkplace.selectedWorkplace.checklist[0].status === 1
                }
              />
              <Checklist
                labelText="Bockar / Stegar"
                setCb={setCb2}
                startValue={
                  selectedWorkplace.selectedWorkplace.checklist[1].status === 1
                }
              />
              <Checklist
                labelText="Ställningar"
                setCb={setCb3}
                startValue={
                  selectedWorkplace.selectedWorkplace.checklist[2].status === 1
                }
              />
              <Checklist
                labelText="Trappor / Höjder"
                setCb={setCb4}
                startValue={
                  selectedWorkplace.selectedWorkplace.checklist[3].status === 1
                }
              />
              <Checklist
                labelText="Personal utrymmen / Wc"
                setCb={setCb5}
                startValue={
                  selectedWorkplace.selectedWorkplace.checklist[4].status === 1
                }
              />
              <Checklist
                labelText="Maskinell utrustning"
                setCb={setCb6}
                startValue={
                  selectedWorkplace.selectedWorkplace.checklist[5].status === 1
                }
              />
              <Checklist
                labelText="Första hjälpen"
                setCb={setCb7}
                startValue={
                  selectedWorkplace.selectedWorkplace.checklist[6].status === 1
                }
              />
              <Checklist
                labelText="Buller"
                setCb={setCb8}
                startValue={
                  selectedWorkplace.selectedWorkplace.checklist[7].status === 1
                }
              />
              <Checklist
                labelText="Kemiska ämnen"
                setCb={setCb9}
                startValue={
                  selectedWorkplace.selectedWorkplace.checklist[8].status === 1
                }
              />
              <Typography
                style={{ textAlign: 'left', paddingTop: theme.baseline * 4 }}
              >
                <Title
                  style={{
                    fontSize: 20,
                    color: theme.colors.button,
                    textAlign: 'center',
                  }}
                >
                  Om olyckan är framme
                </Title>
                <ParagraphBold labelText="Ring 112 om skadan kräver."></ParagraphBold>
                <ParagraphBold labelText="Se till att den skadade är omhändertagen."></ParagraphBold>
                <ParagraphBold labelText="Ring 0735004372 Lars Glassel, Alt 0737003884 Benita Glassel"></ParagraphBold>
                <ParagraphBold labelText="Försök skaffa info om olyckan."></ParagraphBold>
                <ParagraphBold labelText="Kontakta anhörig"></ParagraphBold>
                <ParagraphBold labelText="Försök vara lugn."></ParagraphBold>
              </Typography>
              <Title
                style={{
                  fontSize: 20,
                  color: theme.colors.button,
                  textAlign: 'center',
                }}
              >
                Info om arbetsplats
              </Title>
              <FormTextArea
                labelText={'Kulör/Tapet'}
                name={'color'}
                initialValue={selectedWorkplace.selectedWorkplace.color || ' '}
                required={false}
              ></FormTextArea>
              <Form.Item
                name="date"
                style={{
                  marginTop: theme.baseline * 4,
                  justifyContent: 'center',
                }}
              >
                <ParagraphBold labelText="Datum:"></ParagraphBold>
                <DatePicker
                  placeholder="Välj datum"
                  onChange={onChange}
                  value={moment(
                    selectedWorkplace.selectedWorkplace.date * 1000,
                  )}
                  name="date"
                  disabled
                  style={{
                    width: '100%',
                    backgroundColor: theme.colors.primary,
                  }}
                ></DatePicker>
              </Form.Item>
              <SignatureComponent signature={signature} labelText="Signatur:" />
              <Form.Item style={{ justifyContent: 'center' }}>
                <Button
                  onClick={clearSignature}
                  style={{
                    border: '2px solid #304659',
                    width: '100%',
                    height: 40,
                    fontWeight: 600,
                    borderRadius: theme.borderRadius,
                  }}
                >
                  Gör om signatur
                </Button>
              </Form.Item>
              <Form.Item style={{ justifyContent: 'center' }}>
                <FormSubmitButton buttonText="Updatera" />
              </Form.Item>
            </Form>
          </div>
        </Content>
      </Layout>
    </>
  )
}

export default UpdateWorkplace
