import { FC } from 'react'
import './style.css'
import { Button, Layout, PageHeader } from 'antd'
import MainButton from '../../components/MainButton'
import { getAuth } from 'firebase/auth'
import { useHistory } from 'react-router'
import HeaderComponent from '../../components/HeaderComponent'
import { Content } from 'antd/lib/layout/layout'
import theme from '../../theme'
import adminAccounts from '../../utils/constants'
import OrionPicture from '../../components/OrionPicture'
import PsykoterapiPicture from '../../components/PsykoterapiPicture'
import HandledningPicture from '../../components/HandledningPicture'

type OwnProps = {}

type Props = OwnProps

export const Home: FC<Props> = () => {
  const history = useHistory()
  //check in database if user is admin
  const auth = getAuth()
  let isAdmin = false
  adminAccounts.forEach((account) => {
    if (auth.currentUser?.email === account) {
      isAdmin = true
    }
  })
  if (!auth.currentUser) {
    history.push('/')
  }

  const deleteAccount = async () => {
    const auth = getAuth()
    const shouldDelete = window.confirm(
      'Är du saker att du vill ta bort ditt konto?',
    )
    if (shouldDelete) {
      await auth.currentUser?.delete()
      history.push('/')
    }
  }
  const signOut = async () => {
    const auth = getAuth()
    await auth.signOut()
    history.push('/')
  }

  const navigateToWorkplaceFrom = () => {
    //navigate to workplace form screen
    history.push('/registerWorkplace')
  }
  const navigateToWorkplaceUserFrom = () => {
    //navigate to user workplaces form screen
    history.push('/viewWorkplacesUser')
  }
  const navigateToRegisteredWorkplaces = () => {
    //navigate to navigateToRegisteredWorkplaces screen
    history.push('/viewWorkplaces')
  }
  const navigateToCreateUser = () => {
    //navigate to navigateToCreateUser form screen
    history.push('/createUser')
  }

  return (
    <div
      className="login"
      style={{ height: '100%', width: '100%', textAlign: 'center' }}
    >
      <Layout>
        <HeaderComponent />
        <Content>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignContent: 'center',
              justifyContent: 'center',
              margin: 'auto',
              maxWidth: 500,
              minHeight: '50vh',
            }}
          >
            <PageHeader
              style={{ color: '#304659', fontSize: 25, fontWeight: 600 }}
            >
              Vad vill du göra?
            </PageHeader>
            <MainButton
              buttonText="Registrera ny arbetsplats"
              handleClick={navigateToWorkplaceFrom}
            ></MainButton>
            {!isAdmin && (
              <MainButton
                buttonText="Se mina registerade arbetsplatser"
                handleClick={navigateToWorkplaceUserFrom}
              ></MainButton>
            )}
            {isAdmin && (
              <MainButton
                buttonText="Se registrerade arbetsplatser"
                handleClick={navigateToRegisteredWorkplaces}
              ></MainButton>
            )}
            {isAdmin && (
              <MainButton
                buttonText="Skapa ny användare"
                handleClick={navigateToCreateUser}
              ></MainButton>
            )}
            <Button
              type="link"
              onClick={deleteAccount}
              style={{ marginTop: theme.baseline }}
            >
              Radera konto
            </Button>
            <Button
              type="link"
              onClick={signOut}
              style={{ marginTop: theme.baseline }}
            >
              Logga ut
            </Button>
          </div>
        </Content>
      </Layout>
      {auth.currentUser?.email === 'enequist.anton@gmail.com' && (
        <>
          <OrionPicture></OrionPicture>
          <text>Orion</text>
          <PsykoterapiPicture></PsykoterapiPicture>
          <text>Psykoterapi</text>
          <HandledningPicture></HandledningPicture>
          <text>Handledning</text>
        </>
      )}
    </div>
  )
}

export default Home
