import { FunctionComponent } from 'react'
import { Button } from 'antd'
import theme from '../theme'

type OwnProps = {
  buttonText: string
  date: number
  onPress: () => void
}

type Props = OwnProps

export const FormSubmitButton: FunctionComponent<Props> = ({
  buttonText,
  onPress,
  date,
}) => {
  const multiPlyDate = date * 1000
  var formatedDate = new Date(multiPlyDate).toLocaleDateString('en-US')
  return (
    <Button
      type="primary"
      onClick={onPress}
      style={{
        backgroundColor: theme.colors.primary,
        width: '100%',
        height: 40,
        fontWeight: 600,
        borderRadius: theme.borderRadius,
        color: theme.colors.button,
        marginBottom: theme.baseline,
      }}
    >
      {buttonText} {formatedDate}
    </Button>
  )
}

export default FormSubmitButton
