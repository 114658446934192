import { DocumentSnapshot } from 'firebase/firestore'
import { WorkPlace } from './types'
import validateWorkPlace from './workplaces.model'
import * as workPlaceRepo from './workplaces.repo'

export const createWorkPlace = async (place: WorkPlace) => {
  try {
    const workPlace = await validateWorkPlace(place)
    console.log(workPlace)
    return workPlaceRepo.createWorkPlace(workPlace as WorkPlace)
  } catch (err) {
    return err
  }
}

export const getWorkPlace = (id: string) => {
  return workPlaceRepo.getWorkPlace(id)
}

export const deleteWorkPlace = (id: string) => {
  return workPlaceRepo.deleteWorkPlace(id)
}

export const updateWorkPlace = (id: string, update: Partial<WorkPlace>) => {
  const currentWorkPlace = getWorkPlace(id)

  return workPlaceRepo.updateWorkPlace(id, {
    ...update,
    ...currentWorkPlace,
  } as WorkPlace)
}

export const updateField = async (
  id: string,
  label: string,
  status: number,
) => {
  const currentWorkPlace = (await getWorkPlace(
    id,
  )) as DocumentSnapshot<WorkPlace>
  if (currentWorkPlace.exists()) {
    const workplace = currentWorkPlace.data()
    const labelIndex = workplace.checklist.findIndex(
      (item) => item.label === label,
    )
    const labelToEdit = workplace.checklist[labelIndex]
    const updatedLabel = {
      ...labelToEdit,
      status,
    }
    workplace.checklist[labelIndex] = updatedLabel
    return workPlaceRepo.updateWorkPlace(id, workplace)
  }
}
