import { FunctionComponent } from 'react'
import psyko from '../IMG_4955.png'

type OwnProps = {}

type Props = OwnProps

export const PsykoterapiPicture: FunctionComponent<Props> = () => {
  return <img src={psyko} height={50} width={250} alt="logo" />
}

export default PsykoterapiPicture
