import { Col, Row } from 'antd'
import { FunctionComponent } from 'react'
import logo from '../pigmentaLogo.svg'
import row from '../row.svg'
import backArrow from '../backArrow.svg'
import theme from '../theme'

type OwnProps = {
  goBack: () => void
}

type Props = OwnProps

export const SmallLogoPicture: FunctionComponent<Props> = ({ goBack }) => {
  return (
    <Row justify="space-between">
      <Col>
        <img
          src={backArrow}
          alt="backArrow"
          style={{
            height: theme.baseline * 2,
            width: theme.baseline * 2,
            marginTop: theme.baseline * 3,
            marginLeft: theme.baseline * 2,
          }}
          onClick={goBack}
        />
      </Col>
      <Col>
        <img
          src={logo}
          alt="logo"
          style={{
            height: theme.baseline * 6,
            width: theme.baseline * 20,
            marginBottom: theme.baseline * -3,
            marginTop: theme.baseline,
            marginRight: theme.baseline,
          }}
        />
      </Col>
      <img
        src={row}
        alt="row"
        style={{ height: 61.76, width: '100%', marginBottom: theme.baseline * -3 }}
      />
    </Row>
  )
}

export default SmallLogoPicture
