import joi from 'joi'
import { WorkPlace } from './types'

const checkListObject = joi.object({
  label: joi.string().required(),
  status: joi.number().min(0).max(1).required(),
})

const validateWorkPlace = async (
  workPlace: WorkPlace,
): Promise<WorkPlace | Error> => {
  const workPlaceValidator = joi.object({
    location: joi.string().required(),
    risks: joi.string(),
    checklist: joi.array().items(checkListObject).required(),
    date: joi.date().timestamp().required(),
    signature: joi.string(),
    color: joi.string(),
    worker: joi.string().required(),
  })

  const validationResult = workPlaceValidator.validate(workPlace)
  if (validationResult.error) {
    throw new Error(validationResult.error?.message)
  }
  return workPlace
}

export default validateWorkPlace
