import Paragraph from 'antd/lib/typography/Paragraph'
import { FunctionComponent } from 'react'
import theme from '../theme'

type OwnProps = {
  labelText: string
  fontSize?: number
}

type Props = OwnProps

export const ParagraphBold: FunctionComponent<Props> = ({
  labelText,
  fontSize,
}) => {
  return (
    <Paragraph
      style={{
        fontWeight: 600,
        fontSize: fontSize,
        color: theme.colors.button,
      }}
    >
      {labelText}
    </Paragraph>
  )
}

export default ParagraphBold
